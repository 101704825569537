import React from 'react';
import { Router } from '@reach/router';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ScanResult from '../components/ScanResult';
import Default from '../components/Default';

const NotFoundPage = () => (
  <Layout>
    <div id="wrapper">
      <section className="page-404">
        <div className="container">
          <div className="row">
            <h2 className="section-heading">Error 404</h2>
            <p className="section-subheading">This page could not be found!</p>
            <a href="https://deepware.ai" className="back-button">
              Back to homepage
            </a>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
